import { graphql } from "gatsby"
import { get } from "lodash"

export interface ICoachInfoScreenGraph {
  introSteps: {
    text: string
  }[]
  firstBlockImage: {
    url: string
  }
  firstBlockHeading: string
  firstBlockText: string
  secondBlockImage: {
    url: string
  }
  secondBlockHeading: string
  secondBlockText: string
  substanceText: string
  typicalHeading: string
  typicalList: {
    text: string
  }[]
  youspiredContentHeading: string
  youspiredContentList: string
  devSampleLive?: {
    coachId: string
    videoId: string
  }
  stgSampleLive?: {
    coachId: string
    videoId: string
  }
  sampleLiveBackgroundImage: {
    url: string
  }
  teachText: string
  teachList: {
    text: string
  }[]
  getStartedSteps: {
    heading: string
    text: string
  }[]
  ctaHeading: string
  ctaSubheading: string
  ctaButtonText: string
  ctaDestination: string
  getStartedCtaLabel: string
  getStartedCtaDestination: string
  getStartedCtaIsAppleButton: boolean
}

export const getCoachInfoScreenGetStartedCTAIsAppleButton = (
  content: ICoachInfoScreenGraph
): boolean => {
  return get(content, "getStartedCtaIsAppleButton", false)
}

export const getCoachInfoScreenGetStartedCTADestination = (
  content: ICoachInfoScreenGraph
): string => {
  return get(content, "getStartedCtaDestination", "") || ""
}

export const getCoachInfoScreenGetStartedCTALabel = (
  content: ICoachInfoScreenGraph
): string => {
  return get(content, "getStartedCtaLabel", "") || ""
}

export const getCoachInfoScreenCTADestination = (
  content: ICoachInfoScreenGraph
): string => {
  return get(content, "ctaDestination", "") || ""
}

export const getCoachInfoScreenCTAHeading = (
  content: ICoachInfoScreenGraph
): string => {
  return get(content, "ctaHeading", "") || ""
}

export const getCoachInfoScreenCTASubHeading = (
  content: ICoachInfoScreenGraph
): string => {
  return get(content, "ctaSubheading", "") || ""
}

export const getCoachInfoScreenCTAButtonText = (
  content: ICoachInfoScreenGraph
): string => {
  return get(content, "ctaButtonText", "") || ""
}

export const getCoachInfoScreenTeachText = (
  content: ICoachInfoScreenGraph
): string => {
  return get(content, "teachText", "") || ""
}

export const getCoachInfoScreenGetStartedSteps = (
  content: ICoachInfoScreenGraph
): ICoachInfoScreenGraph["getStartedSteps"] => {
  const steps: ICoachInfoScreenGraph["getStartedSteps"] = get(
    content,
    "getStartedSteps",
    []
  )
  return steps || []
}

export const getCoachInfoScreenTeachList = (
  content: ICoachInfoScreenGraph
): string[] => {
  const steps: ICoachInfoScreenGraph["teachList"] = get(
    content,
    "teachList",
    []
  )
  return steps
    ? steps.map(({ text }: { text: string }) => {
        return text
      })
    : []
}

export const getCoachInfoScreenSampleLiveBackgroundImage = (
  content: ICoachInfoScreenGraph
): string => {
  return get(content, "sampleLiveBackgroundImage.url", "") || ""
}

export const getCoachInfoScreenSubstanceText = (
  content: ICoachInfoScreenGraph
): string => {
  return get(content, "substanceText", "") || ""
}

export const getCoachInfoScreenTypicalHeading = (
  content: ICoachInfoScreenGraph
): string => {
  return get(content, "typicalHeading", "") || ""
}

export const getCoachInfoScreenYouspiredContentHeading = (
  content: ICoachInfoScreenGraph
): string => {
  return get(content, "youspiredContentHeading", "") || ""
}

export const getCoachInfoScreenYouspiredContentList = (
  content: ICoachInfoScreenGraph
): string => {
  return get(content, "youspiredContentList", "") || ""
}

export const getCoachInfoScreenTypicalList = (
  content: ICoachInfoScreenGraph
): string[] => {
  const steps: ICoachInfoScreenGraph["typicalList"] = get(
    content,
    "typicalList",
    []
  )
  return steps
    ? steps.map(({ text }: { text: string }) => {
        return text
      })
    : []
}

export const getCoachInfoScreenSecondBlockImage = (
  content: ICoachInfoScreenGraph
): string => {
  return get(content, "secondBlockImage.url", "") || ""
}

export const getCoachInfoScreenSecondBlockHeading = (
  content: ICoachInfoScreenGraph
): string => {
  return get(content, "secondBlockHeading", "") || ""
}

export const getCoachInfoScreenSecondBlockText = (
  content: ICoachInfoScreenGraph
): string => {
  return get(content, "secondBlockText", "") || ""
}

export const getCoachInfoScreenFirstBlockImage = (
  content: ICoachInfoScreenGraph
): string => {
  return get(content, "firstBlockImage.url", "") || ""
}

export const getCoachInfoScreenFirstBlockHeading = (
  content: ICoachInfoScreenGraph
): string => {
  return get(content, "firstBlockHeading", "") || ""
}

export const getCoachInfoScreenFirstBlockText = (
  content: ICoachInfoScreenGraph
): string => {
  return get(content, "firstBlockText", "") || ""
}

export const getCoachInfoScreenIntroSteps = (
  content: ICoachInfoScreenGraph
): string[] => {
  const steps: ICoachInfoScreenGraph["introSteps"] = get(
    content,
    "introSteps",
    []
  )
  return steps
    ? steps.map(({ text }: { text: string }) => {
        return text
      })
    : []
}

export const query = graphql`
  fragment WebCoachInfoScreenDev on DatoCmsWebCoachInfoScreenDev {
    introSteps {
      text
    }
    firstBlockImage {
      url
    }
    firstBlockHeading
    firstBlockText
    secondBlockImage {
      url
    }
    secondBlockHeading
    secondBlockText
    substanceText
    typicalHeading
    typicalList {
      text
    }
    youspiredContentHeading
    youspiredContentList
    sampleLiveBackgroundImage {
      url
    }
    teachText
    teachList {
      text
    }
    getStartedSteps {
      heading
      text
    }
    ctaHeading
    ctaSubheading
    ctaButtonText
    ctaDestination
    getStartedCtaLabel
    getStartedCtaDestination
    getStartedCtaIsAppleButton
  }

  fragment WebCoachInfoScreenStg on DatoCmsWebCoachInfoScreenStg {
    introSteps {
      text
    }
    firstBlockImage {
      url
    }
    firstBlockHeading
    firstBlockText
    secondBlockImage {
      url
    }
    secondBlockHeading
    secondBlockText
    substanceText
    typicalHeading
    typicalList {
      text
    }
    youspiredContentHeading
    youspiredContentList
    sampleLiveBackgroundImage {
      url
    }
    teachText
    teachList {
      text
    }
    getStartedSteps {
      heading
      text
    }
    ctaHeading
    ctaSubheading
    ctaButtonText
    ctaDestination
    getStartedCtaLabel
    getStartedCtaDestination
    getStartedCtaIsAppleButton
  }

  fragment WebCoachInfoScreenPrd on DatoCmsWebCoachInfoScreenPrd {
    introSteps {
      text
    }
    firstBlockImage {
      url
    }
    firstBlockHeading
    firstBlockText
    secondBlockImage {
      url
    }
    secondBlockHeading
    secondBlockText
    substanceText
    typicalHeading
    typicalList {
      text
    }
    youspiredContentHeading
    youspiredContentList
    sampleLiveBackgroundImage {
      url
    }
    teachText
    teachList {
      text
    }
    getStartedSteps {
      heading
      text
    }
    ctaHeading
    ctaSubheading
    ctaButtonText
    ctaDestination
    getStartedCtaLabel
    getStartedCtaDestination
    getStartedCtaIsAppleButton
  }

  fragment WebCoachInfoScreen on DatoCmsWebCoachInfoScreen {
    introSteps {
      text
    }
    firstBlockImage {
      url
    }
    firstBlockHeading
    firstBlockText
    secondBlockImage {
      url
    }
    secondBlockHeading
    secondBlockText
    substanceText
    typicalHeading
    typicalList {
      text
    }
    youspiredContentHeading
    youspiredContentList
    sampleLiveBackgroundImage {
      url
    }
    teachText
    teachList {
      text
    }
    getStartedSteps {
      heading
      text
    }
    ctaHeading
    ctaSubheading
    ctaButtonText
    ctaDestination
    getStartedCtaLabel
    getStartedCtaDestination
    getStartedCtaIsAppleButton
  }
`
