import React from "react"
import { get } from "lodash"
import { graphql } from "gatsby"
import CoachApplicationScreen from "../screens/CoachApplicationScreen/CoachApplicationScreen"
import SiteLayout from "../components/SiteLayout/SiteLayout"
import SiteWrapper from "../components/SiteWrapper/SiteWrapper"
import { ICoachApplicationScreenGraph } from "../data/cms/coachApplicationScreen"
import PageLocation from "../analytics/components/PageLocation/PageLocation"
import { ROUTES } from "../navigation/routes"
import { getEnvKey } from "../utils/env"
import PageMetaTags, {
  CMSWebMetaSocial,
} from "../components/SiteMeta/PageMetaTags/PageMetaTags"

interface Data extends CMSWebMetaSocial {
  datoCmsWebCoachApplicationScreenDev?: ICoachApplicationScreenGraph
  datoCmsWebCoachApplicationScreenStg?: ICoachApplicationScreenGraph
  datoCmsWebCoachApplicationScreen?: ICoachApplicationScreenGraph
  datoCmsWebCoachApplicationScreenPrd?: ICoachApplicationScreenGraph
}

interface Props {
  data: Data
}

const CoachSignUpApplicationPage: React.FC<Props> = props => {
  const contentKey = getEnvKey({
    devKey: "datoCmsWebCoachApplicationScreenDev",
    stgKey: "datoCmsWebCoachApplicationScreenStg",
    uatKey: "datoCmsWebCoachApplicationScreen",
    prdKey: "datoCmsWebCoachApplicationScreenPrd",
    defaultKey: "datoCmsWebCoachApplicationScreen",
  })
  const content: ICoachApplicationScreenGraph = get(props, `data.${contentKey}`)
  if (!content)
    throw new Error(`datoCmsWebCoachApplicationScreen is missing in props`)
  return (
    <SiteWrapper darkTheme>
      <SiteLayout>
        <PageLocation pageName={ROUTES.coachSignUpApplicationScreen.name}>
          <PageMetaTags data={props} />
          <CoachApplicationScreen content={content} />
        </PageLocation>
      </SiteLayout>
    </SiteWrapper>
  )
}

export default CoachSignUpApplicationPage

export const query = graphql`
  query CoachSignUpApplication(
    $devEnv: Boolean = false
    $stgEnv: Boolean = false
    $uatEnv: Boolean = false
    $prdEnv: Boolean = false
  ) {
    datoCmsWebCoachApplicationScreenDev @include(if: $devEnv) {
      ...CoachApplicationScreenDev
    }
    datoCmsWebCoachApplicationScreenStg @include(if: $stgEnv) {
      ...CoachApplicationScreenStg
    }
    datoCmsWebCoachApplicationScreenPrd @include(if: $prdEnv) {
      ...CoachApplicationScreenPrd
    }
    datoCmsWebCoachApplicationScreen @include(if: $uatEnv) {
      ...CoachApplicationScreen
    }
    datoCmsWebMetaSocialDev @include(if: $devEnv) {
      screenMetaTags: coachApplicationScreenMetaTags {
        ...PageMeta
      }
    }
    datoCmsWebMetaSocialStg @include(if: $stgEnv) {
      screenMetaTags: coachApplicationScreenMetaTags {
        ...PageMeta
      }
    }
    datoCmsWebMetaSocial @include(if: $uatEnv) {
      screenMetaTags: coachApplicationScreenMetaTags {
        ...PageMeta
      }
    }
    datoCmsWebMetaSocialPrd @include(if: $prdEnv) {
      screenMetaTags: coachApplicationScreenMetaTags {
        ...PageMeta
      }
    }
  }
`
