import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import useHubspotForm from "@aaronhayes/react-use-hubspot-form"
import { minHeightCss } from "../SignUpScreen/views/InfoStep/InfoStep"
import { ResponsiveMaxWidthContent } from "../../ui/components/MaxWidthContent/MaxWidthContent"
import {
  StyledCompactText,
  StyledFancyHeading,
  StyledPeriod,
  StyledPurple,
} from "../CoachSignUpScreen/components/TopSection/TopSection"
import theme from "../../ui/theme"
import { ROUTES } from "../../navigation/routes"
import { DESKTOP_BREAKPOINT, MOBILE_BREAKPOINT } from "../../ui/responsive"
import { desktopGridCss } from "../../ui/shared"
import {
  getCoachApplicationScreenText,
  ICoachApplicationScreenGraph,
} from "../../data/cms/coachApplicationScreen"
import { YouspiredFooterCTA } from "../../components/AboutYouspired/AboutYouspired"

const StyledContainer = styled.div`
  ${minHeightCss};
  padding-bottom: 73px;
`

const StyledTopSection = styled.section`
  ${MOBILE_BREAKPOINT} {
    margin-top: 41px;
    margin-bottom: 42px;
    p {
      margin-top: 14.5px;
      padding-right: 40px;
    }
  }

  ${DESKTOP_BREAKPOINT} {
    ${desktopGridCss};
    margin-top: 96px;
    margin-bottom: 96px;
    align-items: center;

    h2 {
      grid-column: span 2;
    }

    p {
      grid-column: 4 / span 3;
    }
  }
`

const StyledHelpLink = styled.a`
  text-decoration: underline;
`

const StyledFormSection = styled.section`
  h4 {
    font-weight: ${theme.fontWeights.bold};
    margin-bottom: 16px;
  }

  &:not(:last-of-type) {
    border-top: 1px solid ${theme.colors.primary};
    padding-top: 9px;
    margin-top: 40px;
  }
`

const StyledInputWrapper = styled.div`
  margin-top: 16px;
`

const StyledInput = styled.input`
  display: block;
  width: 100%;
  background: none;
  border: 0;
  padding: 0;
  font: inherit;
  border: 1px solid ${theme.colors.lightGrey};
  border-radius: ${theme.borderRadius.common}px;
  padding: 9px 16.5px;
`

const CoachApplicationScreen: React.FC<{
  content: ICoachApplicationScreenGraph
}> = ({ content }) => {
  const { loaded, error, formCreated } = useHubspotForm({
    portalId: "6886385",
    formId: "46e14575-9a8b-48c7-9251-6a00316573f3",
    target: "#my-hubspot-form",
  })
  return (
    <>
      <StyledContainer>
        <ResponsiveMaxWidthContent>
          <StyledTopSection>
            <StyledFancyHeading>
              <StyledCompactText>Want to</StyledCompactText>
              <br />
              <StyledPurple>Coach</StyledPurple>
              <StyledPeriod>?</StyledPeriod>
            </StyledFancyHeading>
            <p>{getCoachApplicationScreenText(content)}</p>
          </StyledTopSection>
          <div id="my-hubspot-form" />
          {/* <div> */}
          {/*  <StyledHelpLink as={Link} to={ROUTES.help.path}> */}
          {/*    Get more Help */}
          {/*  </StyledHelpLink> */}
          {/* </div> */}
          {/* <form> */}
          {/*  <StyledFormSection> */}
          {/*    <h4>Personal details</h4> */}
          {/*    <StyledInputWrapper> */}
          {/*      <StyledInput placeholder="Your first name*" /> */}
          {/*    </StyledInputWrapper> */}
          {/*    <StyledInputWrapper> */}
          {/*      <StyledInput placeholder="Your last name*" /> */}
          {/*    </StyledInputWrapper> */}
          {/*    <StyledInputWrapper> */}
          {/*      <StyledInput placeholder="Your email*" /> */}
          {/*    </StyledInputWrapper> */}
          {/*    <StyledInputWrapper> */}
          {/*      <StyledInput placeholder="Your website" /> */}
          {/*    </StyledInputWrapper> */}
          {/*  </StyledFormSection> */}
          {/*  <StyledFormSection> */}
          {/*    <h4>Social details</h4> */}
          {/*    <StyledInputWrapper> */}
          {/*      <StyledInput placeholder="Your Instagram account" /> */}
          {/*    </StyledInputWrapper> */}
          {/*    <StyledInputWrapper> */}
          {/*      <StyledInput placeholder="Number Instagram followers" /> */}
          {/*    </StyledInputWrapper> */}
          {/*    <StyledInputWrapper> */}
          {/*      <StyledInput placeholder="Your Facebook account" /> */}
          {/*    </StyledInputWrapper> */}
          {/*    <StyledInputWrapper> */}
          {/*      <StyledInput placeholder="Number Facebook followers" /> */}
          {/*    </StyledInputWrapper> */}
          {/*    <StyledInputWrapper> */}
          {/*      <StyledInput placeholder="Your twitter account" /> */}
          {/*    </StyledInputWrapper> */}
          {/*    <StyledInputWrapper> */}
          {/*      <StyledInput placeholder="Number twitter followers" /> */}
          {/*    </StyledInputWrapper> */}
          {/*    <StyledInputWrapper> */}
          {/*      <StyledInput placeholder="Your YouTube account" /> */}
          {/*    </StyledInputWrapper> */}
          {/*    <StyledInputWrapper> */}
          {/*      <StyledInput placeholder="Your YouTube account" /> */}
          {/*    </StyledInputWrapper> */}
          {/*  </StyledFormSection> */}
          {/*  <StyledFormSection> */}
          {/*    <h4>Social details</h4> */}
          {/*    <StyledInputWrapper> */}
          {/*      <StyledInput placeholder="Your agent / manager's name" /> */}
          {/*    </StyledInputWrapper> */}
          {/*    <StyledInputWrapper> */}
          {/*      <StyledInput placeholder="Your agent / manager's email" /> */}
          {/*    </StyledInputWrapper> */}
          {/*  </StyledFormSection> */}
          {/*  <StyledFormSection> */}
          {/*    <h4>Your mobile device</h4> */}
          {/*    <div> */}
          {/*      Select which device(s) you'd likely use to broadcast live videos */}
          {/*      from */}
          {/*    </div> */}
          {/*    <div> */}
          {/*      <div>iPhone</div> */}
          {/*      <div>Android</div> */}
          {/*    </div> */}
          {/*  </StyledFormSection> */}
          {/*  <StyledFormSection> */}
          {/*    <h4>Questions, comments or notes</h4> */}
          {/*  </StyledFormSection> */}
          {/*  <StyledFormSection> */}
          {/*    <div> */}
          {/*      <ActionButton theme={ActionButtonTheme.SECONDARY}> */}
          {/*        Submit */}
          {/*      </ActionButton> */}
          {/*    </div> */}
          {/*    <div> */}
          {/*      <Link to={ROUTES.help.path}>Get more Help</Link> */}
          {/*    </div> */}
          {/*  </StyledFormSection> */}
          {/* </form> */}
        </ResponsiveMaxWidthContent>
      </StyledContainer>
      <YouspiredFooterCTA dark />
    </>
  )
}

export default CoachApplicationScreen
