import { graphql } from "gatsby"
import { get } from "lodash"

export interface ICoachApplicationScreenGraph {
  text: string
}

export const getCoachApplicationScreenText = (
  content: ICoachApplicationScreenGraph
): string => {
  return get(content, "text", "") || ""
}

export const query = graphql`
  fragment CoachApplicationScreenDev on DatoCmsWebCoachApplicationScreenDev {
    text
  }

  fragment CoachApplicationScreenStg on DatoCmsWebCoachApplicationScreenStg {
    text
  }

  fragment CoachApplicationScreenPrd on DatoCmsWebCoachApplicationScreenPrd {
    text
  }

  fragment CoachApplicationScreen on DatoCmsWebCoachApplicationScreen {
    text
  }
`
