import React from "react"
import styled from "styled-components"
import {
  DESKTOP_BREAKPOINT,
  MOBILE_BREAKPOINT,
} from "../../../../ui/responsive"
import {
  desktopMaxWidthContentCss,
  maxWidthContentCss,
} from "../../../../ui/components/MaxWidthContent/MaxWidthContent"
import {
  bgImageCss,
  desktopGridCss,
  mobileGridCss,
} from "../../../../ui/shared"
import theme from "../../../../ui/theme"
import VideoIcon from "../../../../assets/inline-assets/video.svg"
import ReactionsIcon from "../../../../assets/inline-assets/reactions.svg"
import RecordIcon from "../../../../assets/inline-assets/record.svg"
import PaymentIcon from "../../../../assets/inline-assets/payment.svg"
import {
  getCoachInfoScreenFirstBlockHeading,
  getCoachInfoScreenFirstBlockImage,
  getCoachInfoScreenFirstBlockText,
  getCoachInfoScreenGetStartedCTADestination,
  getCoachInfoScreenIntroSteps,
  getCoachInfoScreenSecondBlockHeading,
  getCoachInfoScreenSecondBlockImage,
  getCoachInfoScreenSecondBlockText,
  ICoachInfoScreenGraph,
} from "../../../../data/cms/coachInfoScreen"
import {
  fancyCondensedFontCss,
  fancyWideFontCss,
} from "../../../../ui/typography/fonts"
import ActionButton from "../../../../ui/buttons/ActionButton/ActionButton"
import { useSiteContent } from "../../../../components/SiteWrapper/components/SiteContentWrapper/SiteContentWrapper"

const StyledTopSectionWrapper = styled.div`
  ${DESKTOP_BREAKPOINT} {
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      pointer-events: none;
      position: absolute;
      right: 0;
      bottom: 0;
      height: 744px;
      width: 425.61px;
      border: 4px solid ${theme.colors.secondary};
      transform: rotate(-30deg) translate(-20%, 70%);
    }
  }
`

const StyledTopSection = styled.div`
  ${DESKTOP_BREAKPOINT} {
    ${desktopMaxWidthContentCss};
    ${desktopGridCss};
    margin-top: 96px;
    padding-bottom: 112px;
  }
`

const StyledIntro = styled.div`
  ${MOBILE_BREAKPOINT} {
    ${maxWidthContentCss};
  }
  ${DESKTOP_BREAKPOINT} {
    grid-column: span 3;
  }
`

const StyledIntroHeader = styled.header`
  ${MOBILE_BREAKPOINT} {
    margin: 44px 0;
  }
  ${DESKTOP_BREAKPOINT} {
    margin-bottom: 46px;
  }
`

export const StyledFancyHeading = styled.h2`
  ${fancyWideFontCss};
  font-size: 64px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 50px;
  text-transform: uppercase;
`

export const StyledCompactText = styled.span`
  ${fancyCondensedFontCss};
  font-size: 38px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 36px;
`

export const StyledRed = styled.span`
  color: ${theme.colors.primary};
`

export const StyledPeriod = styled.span`
  color: ${theme.colors.primary};
`

export const StyledPurple = styled.span`
  color: ${theme.colors.secondary};
`

const StyledIconList = styled.ul`
  font-size: 24px;
  line-height: ${theme.fontSizes.mediumPlus}px;
  font-weight: ${theme.fontWeights.bold};
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 19px;
    top: 5px;
    bottom: 5px;
    width: 2px;
    background: ${theme.colors.primary};
  }

  > li:not(:first-child) {
    margin-top: 13px;
  }

  > li {
    display: flex;
    position: relative;
    align-items: center;
  }
`

const StyledListIcon = styled.div<{
  dark?: boolean
}>`
  position: relative;
  width: 40px;
  height: 40px;
  background: ${props =>
    props.dark ? theme.colors.darkGrey : theme.colors.white};
  border-radius: 50%;
  border: 2px solid ${theme.colors.primary};
  margin-right: 22.5px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: block;
    width: 20px;
    position: relative;
    top: 1px;

    path {
      stroke: ${props =>
        props.dark ? theme.colors.white : theme.colors.lightGrey};
    }
  }
`

const StyledInfoSection = styled.section`
  ${MOBILE_BREAKPOINT} {
    margin-top: 88px;
    overflow: hidden;
  }
  ${DESKTOP_BREAKPOINT} {
    grid-column: span 3;
    margin-top: 44px;
  }
`

const StyledInfoSecondSection = styled.section`
  ${MOBILE_BREAKPOINT} {
    margin-top: 64px;
    margin-bottom: 100px;
    overflow: hidden;
  }

  ${DESKTOP_BREAKPOINT} {
    grid-column: 2 / span 3;
  }
`

const StyledInfoSectionContent = styled.div`
  ${MOBILE_BREAKPOINT} {
    ${maxWidthContentCss};
  }
`

const StyledInfoBlock = styled.div`
  ${MOBILE_BREAKPOINT} {
    ${mobileGridCss};
  }

  ${DESKTOP_BREAKPOINT} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &:not(:first-child) {
  }
`

const StyledInfoBlockText = styled.div`
  padding-top: 84px;

  ${DESKTOP_BREAKPOINT} {
    //padding-top: 100px;
    padding-top: 0;
  }

  h3 {
    margin-bottom: 8px;
  }

  ${MOBILE_BREAKPOINT} {
    grid-column: span 2;
    margin-right: -${theme.grid.mobileGutterWidth}px;
  }

  ${DESKTOP_BREAKPOINT} {
    max-width: 322px;
    margin-right: 10px;
  }
`

const StyledInfoBlockTextRight = styled(StyledInfoBlockText)`
  ${MOBILE_BREAKPOINT} {
    margin-right: 0;
  }

  ${DESKTOP_BREAKPOINT} {
    margin-left: 10px;
    margin-right: 0;
    //padding-top: 148px;
    padding-top: 0;
  }

  p {
    margin-bottom: ${theme.spacing.medium}px;
  }
`

export const StyledInfoBlockImageDefault = styled.div<{
  image: string
}>`
  ${bgImageCss};
  background-image: url(${props => props.image});
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.16);
  height: 423px;
  border-radius: 8.77px;

  ${DESKTOP_BREAKPOINT} {
    width: 208px;
  }
`

const StyledInfoBlockImage = styled(StyledInfoBlockImageDefault)`
  ${MOBILE_BREAKPOINT} {
    margin-left: 36px;
    margin-right: -49px;
    grid-column: span 2;
  }
`

const StyledInfoBlockImageLeft = styled(StyledInfoBlockImage)`
  ${MOBILE_BREAKPOINT} {
    margin-left: -49px;
    margin-right: 36px;
  }
`

export const StyledInfoLive = styled.div`
  display: inline-block;
  background: ${theme.colors.primary};
  color: ${theme.colors.white};
  padding: 4px 16px 3px 24px;
  position: relative;
  border-radius: 12.75px;
  font-size: 11.9px;
  line-height: 17px;
  margin-bottom: 4px;

  ${DESKTOP_BREAKPOINT} {
    margin-bottom: 7px;
  }

  &::after {
    content: "";
    position: absolute;
    height: 7.44px;
    width: 7.44px;
    border-radius: 50%;
    background: ${theme.colors.white};
    top: 8px;
    left: 10.5px;
  }
`

const StyledBoldHeading = styled.h3`
  font-size: ${theme.fontSizes.mediumPlus}px;
  line-height: 30px;
  font-weight: ${theme.fontWeights.bold};
`

const TopSection: React.FC<{
  content: ICoachInfoScreenGraph
}> = ({ content }) => {
  const introSteps = getCoachInfoScreenIntroSteps(content)
  return (
    <StyledTopSectionWrapper>
      <StyledTopSection>
        <StyledIntro>
          <StyledIntroHeader>
            <StyledFancyHeading>
              <StyledCompactText>Uplifting</StyledCompactText>
              <br />
              <StyledPurple>Real Talk</StyledPurple>
              <StyledPeriod>.</StyledPeriod>
            </StyledFancyHeading>
          </StyledIntroHeader>
          <StyledIconList>
            <li>
              <StyledListIcon>
                <VideoIcon />
              </StyledListIcon>
              {introSteps[0]}
            </li>
            <li>
              <StyledListIcon>
                <ReactionsIcon />
              </StyledListIcon>
              {introSteps[1]}
            </li>
            <li>
              <StyledListIcon>
                <RecordIcon />
              </StyledListIcon>
              {introSteps[2]}
            </li>
            <li>
              <StyledListIcon>
                <PaymentIcon />
              </StyledListIcon>
              {introSteps[3]}
            </li>
          </StyledIconList>
        </StyledIntro>
        <StyledInfoSection>
          <StyledInfoSectionContent>
            <StyledInfoBlock>
              <StyledInfoBlockText>
                <StyledInfoLive>Live</StyledInfoLive>
                <StyledBoldHeading>
                  {getCoachInfoScreenFirstBlockHeading(content)}
                </StyledBoldHeading>
                <p>{getCoachInfoScreenFirstBlockText(content)}</p>
              </StyledInfoBlockText>
              <StyledInfoBlockImage
                image={getCoachInfoScreenFirstBlockImage(content)}
              />
            </StyledInfoBlock>
          </StyledInfoSectionContent>
        </StyledInfoSection>
        <StyledInfoSecondSection>
          <StyledInfoSectionContent>
            <StyledInfoBlock>
              <StyledInfoBlockImageLeft
                image={getCoachInfoScreenSecondBlockImage(content)}
              />
              <StyledInfoBlockTextRight>
                <StyledBoldHeading>
                  {getCoachInfoScreenSecondBlockHeading(content)}
                </StyledBoldHeading>
                <p>{getCoachInfoScreenSecondBlockText(content)}</p>
                <div>
                  <ActionButton
                    as="a"
                    href={getCoachInfoScreenGetStartedCTADestination(content)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Apply To Coach
                  </ActionButton>
                </div>
              </StyledInfoBlockTextRight>
            </StyledInfoBlock>
          </StyledInfoSectionContent>
        </StyledInfoSecondSection>
      </StyledTopSection>
    </StyledTopSectionWrapper>
  )
}

export default TopSection
